import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* <DialogTitle id="form-dialog-title"></DialogTitle> */
  return (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
        新增管理者
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>新增管理者-新增管理者-新增管理者</DialogContentText>
          <TextField autoFocus margin="dense" id="adminAccount" label="帳號" type="text" fullWidth />
          <TextField margin="dense" id="adminName" label="名稱" type="text" fullWidth />
          <TextField margin="dense" id="adminEmail" label="電子郵件" type="email" fullWidth />
          <FormControlLabel control={<Checkbox name="adminEnabled" />} label="啟用" />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            確定新增
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
