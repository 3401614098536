/**
 * 使用Material UI 創建介面
 * 使用Formik 進行表單處理和驗證
 */
import React from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
//import DialogTitle from '@material-ui/core/DialogTitle';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Slide from '@material-ui/core/Slide';
import { createStoreAdmin } from '../../../../redux/actions/StoreAdminAction';
import { kebabCase } from 'lodash';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FormDialog() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [adminAccountVO, setAdminAccountVO] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
    setAdminAccountVO(initialValues);
  };

  const handleClose = () => {
    setOpen(false);
    // const newAccount = {
    //   adminAccount: document.getElementById('adminAccount').value,
    //   adminName: document.getElementById('adminName').value,
    //   adminEmail: document.getElementById('adminEmail').value,
    //   adminEnabled: document.getElementById('adminEnabled').value,
    // };
    // const adminAccount = document.getElementById('adminAccount').value;
    // const adminName = document.getElementById('adminName').value;
    // const adminEmail = document.getElementById('adminEmail').value;

    // console.log(newAccount);
    // dispatch(createStoreAdmin(adminAccount, 'pwd', adminName, adminEmail, true));

    // console.log(adminAccountVO);
    // dispatch(
    //   createStoreAdmin(adminAccountVO.adminAccount, 'pwd', adminAccountVO.adminName, adminAccountVO.adminEmail, true),
    // );

    // const createNewAccount = new Promise(resolve => {
    //   setTimeout(() => {
    //     resolve(() => {
    //       // eslint-disable-next-line no-undef
    //       dispatch(createStoreAdmin(adminAccount, 'pwd', adminName, adminEmail, true));
    //       // .then(response => {
    //       //   //console.log(response);
    //       // })
    //       // .catch(e => {
    //       //   console.log(e);
    //       // });
    //     });
    //   }, 600);
    // });

    // createNewAccount.then(
    //   success => console.log(success),
    //   failure => console.log(failure),
    // );
  };
  const initialValues = {
    adminAccount: '',
    adminEmail: '',
    adminPassword: '',
    confirmPassword: '',
    adminName: '',
    adminEnabled: false,
  };
  const handleOnSubmit = values => {
    setOpen(false);
    dispatch(
      createStoreAdmin(values.adminAccount, values.adminPassword, values.adminName, values.adminEmail, values.adminEnabled),
    );
    setAdminAccountVO({
      adminAccount: values.adminAccount,
      adminPassword: values.adminPassword,
      adminName: values.adminName,
      adminEmail: values.adminEmail,
      adminEnabled: values.adminEnabled,
    });
  };
  const validationSchema = yup.object().shape({
    adminAccount: yup.string('Admin account').required('必填欄位'),
    adminPassword: yup
      .string()
      .required('必填欄位')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*?])(?=.{8,32})/,
        '密碼至少包含八個字符、至少包含一個數字、同時包含小寫字母和大寫字母以及特殊字符，例如 # 、 ? 、 !',
      ),
    confirmPassword: yup
      .string()
      .required('必填欄位')
      .when('adminPassword', {
        is: val => (val && val.length > 0 ? true : false),
        then: yup.string().oneOf([yup.ref('adminPassword')], '請輸入相同密碼'),
      }),
    adminName: yup.string('Admin Name').required('必填欄位'),
    adminEmail: yup
      .string('Email Address')
      .email('電子郵件格式有誤')
      .required('必填欄位'),
  });

  // const onChangeAdminAccount = e => {
  //   console.log(e.target.value);
  //   // setAdminAccountVO({
  //   //   adminAccount: e.target.value,
  //   // });
  // };

  // const onChangeAdminName = e => {
  //   console.log(e.target.value);
  //   setAdminAccountVO({
  //     adminName: e.target.value,
  //   });
  // };

  return adminAccountVO ? (
    <Box>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Add New
      </Button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>新增管理者</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>請填入下列資訊</DialogContentText>
          <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={validationSchema}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    required
                    as={TextField}
                    autoFocus
                    margin="dense"
                    id="adminAccount"
                    name="adminAccount"
                    label="店管帳號"
                    type="text"
                    fullWidth
                    value={values.adminAccount}
                    onChange={handleChange}
                    error={touched.adminAccount && Boolean(errors.adminAccount)}
                    helperText={touched.adminAccount && errors.adminAccount}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="adminPassword"
                    name="adminPassword"
                    label="店管密碼"
                    type="password"
                    fullWidth
                    value={values.adminPassword}
                    onChange={handleChange}
                    error={touched.adminPassword && Boolean(errors.adminPassword)}
                    helperText={touched.adminPassword && errors.adminPassword}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="confirmPassword"
                    name="confirmPassword"
                    label="再次輸入密碼"
                    type="password"
                    fullWidth
                    value={values.confirmPassword}
                    onChange={handleChange}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="adminName"
                    name="adminName"
                    label="店管名稱"
                    type="text"
                    fullWidth
                    value={values.adminName}
                    onChange={handleChange}
                    error={touched.adminName && Boolean(errors.adminName)}
                    helperText={touched.adminName && errors.adminName}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="adminEmail"
                    name="adminEmail"
                    label="店管郵件"
                    fullWidth
                    value={values.adminEmail}
                    onChange={handleChange}
                    error={touched.adminEmail && Boolean(errors.adminEmail)}
                    helperText={touched.adminEmail && errors.adminEmail}
                  />
                  <Field
                    as={FormControlLabel}
                    control={<Checkbox id="adminEnabled" name="adminEnabled" />}
                    label="啟用"
                    checked={values.adminEnabled}
                    onChange={handleChange}
                  />
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定新增
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  ) : (
    <Button variant="outlined" color="primary" onClick={handleClickOpen}>
      Add New
    </Button>
  );
}
