import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Dashboard = ({ match }) => {
    const requestUrl = match.url.replace(/\/$/, '');
    return (
        <Suspense fallback={PageLoader}>
            <Switch>
                <Route path={`${requestUrl}/addLead`} component={lazy(() => import("./Leads/AddLead"))}  />
            </Switch>
        </Suspense>
    );
};

export default Dashboard;