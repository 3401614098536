import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ApiService from 'services/apiService';
import localStorageService from 'services/LocalStorageService';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  section: {
    '&:not(:last-child)': {
      marginBottom: theme.typography.pxToRem(32),
    },
  },
  sectionHeading: {
    marginBottom: theme.typography.pxToRem(16),
  },
}));

const breadcrumbs = [
  // { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  // { label: <IntlMessages id={'pages.editTable'} />, isActive: true },
];
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const initialLogData = {
  store: { '': 0 },
  activity: { '': 0 },
  product: { '': 0 },
  pointcard: { '': 0 },
  coupon: { '': 0 },
  member: { '': 0 },
  cart: { '': 0 },
  game: { '': 0 },
};
const initialLabelData = ['', '', '', '', '', ''];
const initialHashTable = { 1234567: '' };
const initialFilterDate = {
  start: `${new Date().getFullYear()}-${(new Date().getMonth() - 2).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
  end: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, 0)}-${new Date()
    .getDate()
    .toString()
    .padStart(2, 0)}`,
};

/**函式區 */
// 取得所有商家後台點擊次數 函式
const getAnalysisLog = () => {
  return new Promise((resolve, reject) => {
    const token = localStorageService.getToken();
    ApiService.log
      .listFrontendAllStore(token)
      .then(response => {
        const result = {};
        const rawData = JSON.parse(JSON.stringify(response.data.logData));
        rawData.store = Object.entries(rawData.store);
        rawData.activity = Object.entries(rawData.activity);
        rawData.product = Object.entries(rawData.product);
        rawData.pointcard = Object.entries(rawData.pointcard);
        rawData.coupon = Object.entries(rawData.coupon);
        rawData.member = Object.entries(rawData.member);
        rawData.cart = Object.entries(rawData.cart);
        rawData.game = Object.entries(rawData.game);
        delete rawData.labels;
        result.labels = response.data.logData.labels;
        result.logData = rawData;
        // console.log('result frontend', result)
        result.countTotal = response.data.countTotal;
        resolve(result);
      })
      .catch(error => reject(error));
  });
};
/** hash 函式
 * x 軸labels(時間)轉為timestamp的hash table
 * @param {Array} labels
 * @returns
 */
const hashfn = labels => {
  let hashTable = {};
  labels.map(label => {
    hashTable[new Date(label).setHours(0, 0, 0, 0)] = label;
  });
  return hashTable;
};
// x軸label過濾(時間)
const labelFilter = (labels, startTime, endTime) =>
  new Promise((resolve, reject) => {
    // x 時間軸filter
    let filteredDateLabel = labels
      .map(label => new Date(label).setHours(0, 0, 0, 0))
      .filter(item => item >= startTime && item <= endTime);
    resolve(filteredDateLabel);
  });
// 資料隨著時間過濾
const dataFilter = (logData, startTime, endTime) =>
  new Promise((resolve, reject) => {
    // console.log('log data', logData)
    const result = { count: 0, data: {} };
    let countTotal = 0;
    for (let key in logData) {
      // console.log(key, logData[key])
      result.data[key] = logData[key].filter(singleDate => {
        const timestamp = new Date(singleDate[0]).setHours(0, 0, 0, 0);
        return timestamp >= startTime && timestamp <= endTime;
      });
      // 總次數計算
      let count = result.data[key].reduce((accumulator, currentValue, currentIndex, array) => {
        return accumulator + currentValue[1];
      }, 0);
      countTotal += count;
    }
    result.count = countTotal;
    // console.log('result',result)
    resolve(result);
  });

// chart.js 設定config
const options = {
  maintainAspectRatio: false,
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '前端使用者觸及曲線圖',
    },
  },
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export default function FrontendAllStoreTable() {
  const classes = useStyles();
  const [labels, setLebels] = React.useState(initialLabelData);
  const [logdata, setLogData] = React.useState(initialLogData);
  const [countTotal, setCountTotal] = React.useState(0);
  const [filteredLabel, setFilteredLabel] = React.useState(initialLabelData);
  const [filteredLogdata, setFilteredLogData] = React.useState(initialLogData);
  const [filteredCountTotal, setFilteredCountTotal] = React.useState(0);
  const [hashTable, setHashTable] = React.useState(initialHashTable);
  React.useEffect(() => {
    getAnalysisLog()
      .then(result => {
        setCountTotal(result.countTotal);
        setFilteredCountTotal(result.countTotal);
        setLebels(result.labels);
        setFilteredLabel(result.labels);
        // console.log(result)
        setLogData(result.logData);
        setFilteredLogData(result.logData);
        const labelHashTable = hashfn(result.labels);
        setHashTable(labelHashTable);
      })
      .catch(error => {
        console.log('error', error);
        // 出現錯誤回復初始設定值
        setFilteredLabel(initialLabelData);
        setFilteredLogData(initialLogData);
        setFilteredCountTotal(0);
        setLebels(initialLabelData);
        setLogData(initialLogData);
        setFilterDate(initialFilterDate);
      });
  }, []);

  const data = {
    labels: filteredLabel,
    datasets: [
      {
        label: '商家資訊',
        data: filteredLogdata.store,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: '首頁活動資訊',
        data: filteredLogdata.activity,
        borderColor: 'rgba(255, 159, 64)',
        backgroundColor: 'rgba(255, 159, 64, 0.2)',
      },
      {
        label: '商品資訊',
        data: filteredLogdata.product,
        borderColor: 'rgba(255, 205, 86)',
        backgroundColor: 'rgba(255, 205, 86, 0.2)',
      },
      {
        label: '集點卡資訊',
        data: filteredLogdata.pointcard,
        borderColor: 'rgba(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: '優惠資訊',
        data: filteredLogdata.coupon,
        borderColor: 'rgba(54, 162, 235)',
        backgroundColor: 'rgba(54, 162, 235, 0.2)',
      },
      {
        label: '會員資訊',
        data: filteredLogdata.member,
        borderColor: 'rgba(153, 102, 255)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
      {
        label: '購物車資訊',
        data: filteredLogdata.cart,
        borderColor: 'rgba(201, 203, 207)',
        backgroundColor: 'rgba(201, 203, 207, 0.2)',
      },
      {
        label: '遊戲資訊',
        data: filteredLogdata.game,
        borderColor: 'rgb(128, 155, 0)',
        backgroundColor: 'rgb(128, 155, 0, 0.2)',
      },
    ],
  };
  // 日期輸入
  const [filterDate, setFilterDate] = React.useState(initialFilterDate);
  const handleStartChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, start: event.target.value }));
  };
  const handleEndChange = event => {
    event.persist();
    setFilterDate(state => ({ ...state, end: event.target.value }));
  };

  // 過濾搜尋
  const handleClick = event => {
    event.preventDefault();
    const startTime = new Date(filterDate.start).setHours(0, 0, 0, 0);
    const endTime = new Date(filterDate.end).setHours(0, 0, 0, 0);
    labelFilter(labels, startTime, endTime).then(res => {
      const filteredDateLabel = res.map(item => hashTable[item]);
      setFilteredLabel(filteredDateLabel);
    });
    dataFilter(logdata, startTime, endTime).then(res => {
      setFilteredCountTotal(res.count);
      setFilteredLogData(res.data);
    });
  };
  // 清除
  const handleClickReset = event => {
    event.preventDefault();
    setFilteredLabel(labels);
    setFilteredLogData(logdata);
    setFilterDate(initialFilterDate);
    setFilteredCountTotal(countTotal);
  };
  return (
    <Box className={classes.section} style={{ height: '400px', paddingBottom: '150px' }}>
      <h3>前端操作-所有使用者觸及曲線圖 共:{filteredCountTotal}</h3>
      <p>
        開始時間: {filterDate.start} 結束時間: {filterDate.end}
      </p>
      <input type="date" id="start" name="start" onChange={handleStartChange} value={filterDate.start} />
      <input type="date" id="end" name="end" onChange={handleEndChange} value={filterDate.end} />

      <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClick}>
        過濾
      </Button>
      <Button variant="outlined" color="primary" style={{ marginLeft: '10px' }} onClick={handleClickReset}>
        清除
      </Button>
      <Line data={data} width={600} height={400} options={options} />
    </Box>
  );
}
