import {
  CREATE_STORE_ADMIN,
  RETRIEVE_STORE_ADMINS,
  UPDATE_STORE_ADMIN,
  DELETE_STORE_ADMIN,
  DELETE_ALL_STORE_ADMINS,
} from "../../@jumbo/constants/ActionTypes";
  
  const initialState = [];
  
  function storeAdminReducer(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case CREATE_STORE_ADMIN:
        return [...state, payload];
  
      case RETRIEVE_STORE_ADMINS:
        return payload;
  
      case UPDATE_STORE_ADMIN:
        return payload;
        /*return state.map((storeAdmin) => {
          if (storeAdmin.id === payload.id) {
            return {
              ...storeAdmin,
              ...payload,
            };
          } else {
            return state;
          }
        });*/
  
      case DELETE_STORE_ADMIN:
        return payload;
        // return state.filter(({ id }) => id !== payload.id);
  
      case DELETE_ALL_STORE_ADMINS:
        return [];
  
      default:
        return state;
    }
  };
  
  export default storeAdminReducer;