/**
 * 使用Formik 進行表單處理和驗證
 */
import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import localStorageService from 'services/LocalStorageService';
import ApiService from 'services/apiService';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialValues = {
  lineChannelSecret: '',
  lineAccessToken: '',
  lineLiffId: '',
};
export default function EditFormDialog(props) {
  const { open, setIsDialogOpen, children, rowData, setRowData, ...others } = props;
  const [thirdPartyData, setThirdPartyData] = React.useState(initialValues);

  useEffect(() => {
    if (rowData !== null && rowData.storeCode !== undefined) {
      // console.log('rowData', rowData)
      setThirdPartyData({
        lineChannelSecret: rowData.lineChannelSecret,
        lineAccessToken: rowData.lineAccessToken,
        lineLiffId: rowData.lineLiffId,
      });
    }
  }, [rowData]);

  const handleClose = () => {
    setIsDialogOpen(false);
    setThirdPartyData(initialValues);
    setRowData('');
  };
  const handleOnSubmit = values => {
    // console.log('values', values)
    const accessToken = localStorageService.getToken();
    if (rowData.lineAccessToken.length > 0 || rowData.lineChannelSecret.length > 0 || rowData.lineLiffId.length > 0) {
      ApiService.ThirdParty.modify(rowData.storeCode, accessToken, values).then(res => {
        console.log('third party modify res', res);
      });
    } else {
      ApiService.ThirdParty.add(rowData.storeCode, accessToken, values).then(res => {
        console.log('third party add res', res);
      });
    }
    setIsDialogOpen(false);
  };
  return (
    <Box>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} TransitionComponent={Transition}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          <span>第三方串接資料</span>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>修改下列資訊</DialogContentText>
          <Formik initialValues={thirdPartyData} onSubmit={handleOnSubmit}>
            {props => {
              const {
                values,
                touched,
                errors,
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
              } = props;
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    required
                    as={TextField}
                    autoFocus
                    margin="dense"
                    id="lineChannelSecret"
                    name="lineChannelSecret"
                    label="Line 的 Channel Secret"
                    type="text"
                    fullWidth
                    value={values.lineChannelSecret}
                    onChange={handleChange}
                    error={touched.lineChannelSecret && Boolean(errors.lineChannelSecret)}
                    helperText={touched.lineChannelSecret && errors.lineChannelSecret}
                  />
                  <Field
                    required
                    as={TextField}
                    margin="dense"
                    id="lineAccessToken"
                    name="lineAccessToken"
                    label="Line 的 AccessToken"
                    type="text"
                    fullWidth
                    value={values.lineAccessToken}
                    onChange={handleChange}
                    error={touched.lineAccessToken && Boolean(errors.lineAccessToken)}
                    helperText={touched.lineAccessToken && errors.lineAccessToken}
                  />
                  <Field
                    // required
                    as={TextField}
                    margin="dense"
                    id="lineLiffId"
                    name="lineLiffId"
                    label="Line 的 liff-Id"
                    fullWidth
                    value={values.lineLiffId}
                    onChange={handleChange}
                    error={touched.lineLiffId && Boolean(errors.lineLiffId)}
                    helperText={touched.lineLiffId && errors.lineLiffId}
                  />
                  <DialogActions>
                    <Button
                      type="button"
                      className="outline"
                      color="primary"
                      onClick={handleReset}
                      disabled={!dirty || isSubmitting}>
                      清除
                    </Button>
                    <Button type="submit" color="primary" disabled={isSubmitting}>
                      確定
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
