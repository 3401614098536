import http from '../http-common';

const getAll = () => {
  return http.get('/store');
};

const get = storeCode => {
  return http.get(`/store/${storeCode}`);
};

const getByStoreCode = storeCode => {
  return http.get(`/store/info/${storeCode}`);
};

const create = data => {
  console.log('create store data', data);
  return http.post('/store', data);
};

const update = (storeCode, data) => {
  return http.put(`/store/${storeCode}`, data);
};

const updateByStoreCode = (storeCode, data) => {
  return http.put(`/store/info/${storeCode}`, data);
};

const remove = id => {
  return http.delete(`/store/${id}`);
};

// const removeAll = () => {
//   return http.delete(`/store`);
// };

// const findByAdminAccount = adminAccount => {
//   return http.get(`/storeAdmin?adminAccount=${adminAccount}`);
// };

const findAllByPage = (page, size) => {
  return http.get(`/storeAdmin?page=${page}&size=${size}`);
};

const findAllMembersByStore = (storeCode, accessToken) => {
  return http.get(`/store/members/${storeCode}`, [], {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
  });
};

const StoreAdminService = {
  getAll,
  get,
  getByStoreCode,
  create,
  update,
  updateByStoreCode,
  remove,
  // removeAll,
  // findByAdminAccount,
  findAllByPage,
  findAllMembersByStore,
};

export default StoreAdminService;
