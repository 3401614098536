import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  retrieveStoreAdmins,
  updateStoreAdmin,
  deleteStoreAdmin
} from "../../../redux/actions/StoreAdminAction";
import MaterialTable from 'material-table';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { lighten } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function MaterialTableDemo() {

  const dispatch = useDispatch();
  const storeAdmins = useSelector((state) => state.storeAdminReducer);
  
  useEffect(() => {
    dispatch(retrieveStoreAdmins());
  }, [dispatch]);

  const updateContent = (id, data) => {
    dispatch(updateStoreAdmin(id, data))
      .then(response => {
        //console.log(response);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const deleteContent = (id) => {
    dispatch(deleteStoreAdmin(id))
      .then(() => {
        //props.history.push("/storeAdmin");
      })
      .catch(e => {
        console.log(e);
      });
  };


  const classes = useStyles();
  const [state, setState] = React.useState({
    columns: [
      { title: "No.", render: rowData => rowData.tableData.id + 1 },
      { title: 'adminAccount', field: 'adminAccount' },
      { title: 'adminName', field: 'adminName' },
      { title: 'adminEmail', field: 'adminEmail' },
      { title: 'adminEnabled', field: 'adminEnabled', type: 'boolean' },
      { title: 'store', field: 'store.storeName', editable: 'never' },
      /* { title: 'Birth Place', field: 'birthCity', lookup: { 34: 'İstanbul', 63: 'Şanlıurfa' }, }, */
    ],
    data: [],
  });

  return (
    <div>
    <MaterialTable
      className={classes.root}
      icons={tableIcons}
      title="Editable Example"
      columns={state.columns}
      data={storeAdmins}
      options={{
        actionsColumnIndex: -1,
      }}
      editable={{
        // onRowAdd: newData =>
        //   new Promise(resolve => {
        //     setTimeout(() => {
        //       resolve();
        //       setState(prevState => {
        //         const data = [...prevState.data];
        //         data.push(newData);
        //         return { ...prevState, data };
        //       });
        //     }, 600);
        //   }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve( updateContent(oldData.id, newData) );
              // if (oldData) {
              //   setState(prevState => {
              //     const data = [...prevState.data];
              //     data[data.indexOf(oldData)] = newData;
              //     return { ...prevState, data };
              //   });
              //   console.log("999");
              // }
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve( deleteContent(oldData.id) );
              //setState(prevState => {
              //  const data = [...prevState.data];
              //  data.splice(data.indexOf(oldData), 1);
              //  return { ...prevState, data };
              //});
            }, 600);
          }),
      }}
    />

    </div>
  );
}
